<template>
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9.28571L9.4717 17L24 2"
      stroke="currentColor"
      stroke-width="5"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomCheckmarkIcon",
};
</script>
